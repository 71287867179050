import { ZixiObject } from "../../models/shared";

export class Grid {
    _frontData?: {
        sortableStatus: string;
    };
    all_resource_tags: number;
    broadcasters: number;
    channels: number;
    clusters: number;
    cols: number;
    created_at: string;
    customer_id: number;
    cycle_pagination: number;
    cycle_pagination_interval: number;
    display_bandwidth: number;
    display_bitrate: number;
    display_cpu: number;
    display_detailed_status: number;
    display_ip: number;
    display_latency: number;
    display_ram: number;
    display_tr101: number;
    display_version: number;
    display_overlay: number;
    show_info_btm: number;
    fit_screen: boolean;
    excludeResourceTags: [];
    feeders: number;
    thumbnails: number;
    id: number;
    includeResourceTags: [];
    include_acknowledged: number;
    include_muted: number;
    include_not_acknowledged: number;
    include_not_muted: number;
    name: string;
    name_filter_exclude: string;
    name_filter_include: string;
    public: number;
    receivers: number;
    zecs: number;
    rows: number;
    show_disabled: number;
    show_error: number;
    show_ok: number;
    show_pending: number;
    show_warning: number;
    sort_by: string;
    sources: number;
    targets: number;
    thumbnails_refresh_interval: number;
    updated_at: string;
    user_id: number;
    objects: ZixiObject[];
    hasFullDetails: boolean;
}
