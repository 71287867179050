import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, ReplaySubject, firstValueFrom, share } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { AuthService } from "src/app/services/auth.service";
//
import { SCTELog, SCTEFilter } from "./scte";
import { APIResponse, Source } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class ScteService {
    scteLogs: Observable<SCTELog[]>;
    private scteLogs$: ReplaySubject<SCTELog[]>;
    private dataStore: {
        scteLogs: SCTELog[];
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            scteLogs: []
        };

        this.scteLogs$ = new ReplaySubject(1) as ReplaySubject<SCTELog[]>;
        this.scteLogs = this.scteLogs$.asObservable();
    }

    getSCTEParameters(scteFilter: SCTEFilter) {
        let filter = new HttpParams();
        if (scteFilter) {
            if (scteFilter.pageSize) {
                filter = filter.append("pageSize", scteFilter.pageSize.toString());
            } else {
                filter = filter.append("pageSize", "20");
            }
            if (scteFilter.offset) {
                filter = filter.append("offset", scteFilter.offset.toString());
            }
            if (scteFilter.fromDate) filter = filter.append("from", scteFilter.fromDate.toISOString());
            if (scteFilter.toDate) filter = filter.append("to", scteFilter.toDate.toISOString());
            if (scteFilter.resourceTags) {
                scteFilter.resourceTags.forEach(tag => {
                    filter = filter.append("resource_tags", tag);
                });
            }
            if (scteFilter.search) filter = filter.append("search", scteFilter.search);
            if (scteFilter.source_id) {
                scteFilter.source_id.forEach(source => {
                    filter = filter.append("source_id", source);
                });
            }
        }
        return filter;
    }

    getSCTELogs(scteFilter: SCTEFilter): Observable<SCTELog[]> {
        this.http
            .get<APIResponse<SCTELog[]>>(Constants.apiUrl + Constants.apiUrls.scte35, {
                params: this.getSCTEParameters(scteFilter)
            })
            .subscribe(
                data => {
                    this.dataStore.scteLogs = data.result;
                    this.scteLogs$.next(Object.assign({}, this.dataStore).scteLogs);
                },
                // eslint-disable-next-line no-console
                error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SCTE_LOGS"), error)
            );
        return this.scteLogs;
    }

    async getSCTELog(log: SCTELog): Promise<SCTELog | false> {
        try {
            const result = await this.http
                .get<APIResponse<SCTELog>>(Constants.apiUrl + Constants.apiUrls.scte35 + "/" + log.id)
                .toPromise();
            const logDetails: SCTELog = result.result;
            return logDetails;
        } catch (error) {
            return false;
        }
    }

    async getSourceSCTELogs(source: Source, scteFilter: SCTEFilter) {
        const response = await firstValueFrom(
            this.http
                .get<APIResponse<SCTELog[]>>(
                    Constants.apiUrl + Constants.apiUrls.source + "/" + source.id + Constants.apiUrls.scte35,
                    { params: this.getSCTEParameters(scteFilter) }
                )
                .pipe(share())
        ).catch(error => {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SOURCE_SCTE_LOGS"), error);
            return { result: [] as SCTELog[] };
        });
        this.dataStore.scteLogs = response.result;
        this.scteLogs$.next(Object.assign({}, this.dataStore).scteLogs);
        return response.result;
    }

    clearSCTELogs() {
        this.dataStore.scteLogs = [];
        this.scteLogs$.next(Object.assign({}, this.dataStore).scteLogs);
    }

    async refreshSCTELogs(scteFilter: SCTEFilter) {
        const data = await this.http
            .get<APIResponse<SCTELog[]>>(Constants.apiUrl + Constants.apiUrls.scte35, {
                params: this.getSCTEParameters(scteFilter)
            })
            .toPromise();
        this.dataStore.scteLogs = data.result;
        this.scteLogs$.next(Object.assign({}, this.dataStore).scteLogs);
    }

    async refreshSourceSCTELogs(source: Source, scteFilter: SCTEFilter) {
        const data = await this.http
            .get<APIResponse<SCTELog[]>>(
                Constants.apiUrl + Constants.apiUrls.source + "/" + source.id + Constants.apiUrls.scte35,
                {
                    params: this.getSCTEParameters(scteFilter)
                }
            )
            .toPromise();

        this.dataStore.scteLogs = this.dataStore.scteLogs.concat(data.result);
        this.scteLogs$.next(Object.assign({}, this.dataStore).scteLogs);
    }

    async loadMoreSCTELogs(scteFilter: SCTEFilter) {
        const data = await this.http
            .get<APIResponse<SCTELog[]>>(Constants.apiUrl + Constants.apiUrls.scte35, {
                params: this.getSCTEParameters(scteFilter)
            })
            .toPromise();

        this.dataStore.scteLogs = this.dataStore.scteLogs.concat(data.result);
        this.scteLogs$.next(Object.assign({}, this.dataStore).scteLogs);
    }

    async loadMoreSourceSCTELogs(source: Source, scteFilter: SCTEFilter) {
        const data = await this.http
            .get<APIResponse<SCTELog[]>>(
                Constants.apiUrl + Constants.apiUrls.source + "/" + source.id + Constants.apiUrls.scte35,
                {
                    params: this.getSCTEParameters(scteFilter)
                }
            )
            .toPromise();

        this.dataStore.scteLogs = this.dataStore.scteLogs.concat(data.result);
        this.scteLogs$.next(Object.assign({}, this.dataStore).scteLogs);
    }

    private toDate = new ReplaySubject(1) as ReplaySubject<moment.Moment>;
    private fromDate = new ReplaySubject(1) as ReplaySubject<moment.Moment>;
    private pickerToDate = new ReplaySubject(1) as ReplaySubject<moment.Moment>;
    private pickerFromDate = new ReplaySubject(1) as ReplaySubject<moment.Moment>;

    get getToDate() {
        return this.toDate.asObservable();
    }
    setToDate(d: moment.Moment) {
        this.toDate.next(d);
    }

    get getFromDate() {
        return this.fromDate.asObservable();
    }
    setFromDate(d: moment.Moment) {
        this.fromDate.next(d);
    }

    get getPickerToDate() {
        return this.pickerToDate.asObservable();
    }
    setPickerToDate(d: moment.Moment) {
        this.pickerToDate.next(d);
    }

    get getPickerFromDate() {
        return this.pickerFromDate.asObservable();
    }
    setPickerFromDate(d: moment.Moment) {
        this.pickerFromDate.next(d);
    }
}
