import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Constants } from "./../../../constants/constants";
import { AnyTarget, TargetObjectType } from "../../../pages/channels/channel";
import { TargetsService } from "src/app/pages/targets/targets.service";

@Component({
    selector: "zx-target",
    template: `
        <div class="ellipsis d-inline" title="{{ target | errorMessage }}" *ngIf="target.name">
            <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'TARGET' | translate }}">T</span>
            <zx-status-full
                [icon]="showStatusIcon"
                [model]="target"
                [status]="status || (target.generalStatus | statusClass)"
                [showStatusText]="showStatusText"
                [showOtherIcons]="showOtherIcons"
                [channelDisabled]="
                    target.adaptive_channel_id && target.adaptiveChannel
                        ? !target.adaptiveChannel.is_enabled
                        : target.delivery_channel_id && target.deliveryChannel
                        ? !target.deliveryChannel.is_enabled
                        : target.mediaconnect_flow_id && target.mediaconnectFlow
                        ? !target.mediaconnectFlow.is_enabled
                        : false
                "
            ></zx-status-full>
            <fa-icon
                *ngIf="ts.isAltPath(target) && showOtherIcons"
                class="me-1"
                icon="alt"
                size="sm"
                title="{{ 'ALT_PATH_ACTIVE' | translate }}"
            ></fa-icon>
            <a
                *ngIf="showLink"
                [routerLink]="['/' + urls.targets, model.apiType || target.type, target.id | uId, target.name]"
                (click)="$event.stopPropagation()"
                ><ngb-highlight title="{{ target.name }}" [result]="target.name" [term]="searchTerm"></ngb-highlight
            ></a>
            <span *ngIf="!showLink"
                ><ngb-highlight title="{{ target.name }}" [result]="target.name" [term]="searchTerm"></ngb-highlight
            ></span>
        </div>
    `
})
export class ZxTargetComponent implements OnInit, OnChanges {
    @Input() model: AnyTarget | TargetObjectType;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() searchTerm?: string | string[];

    urls = Constants.urls;
    target: TargetObjectType;

    constructor(private ts: TargetsService) {}

    ngOnInit() {
        if (!this.model) return;
        if ("target" in this.model && typeof this.model.target !== "string") this.target = this.model.target;
        else this.target = this.model as TargetObjectType;
        if (this.target.readOnly) this.showLink = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.model) return;
        if ("target" in this.model && typeof this.model.target !== "string") this.target = this.model.target;
        else this.target = this.model as TargetObjectType;
        if (this.target.readOnly) this.showLink = false;
    }
}
